<template>
  <div class="auto-autosuggest-wrap-component">
    <div v-if="disabled">
      <b-form-input
        v-model="bindingModel"
        size="sm"
        :placeholder="bindingPlaceholder"
        :title="bindingModelTitle"
        class="input-style text-left"
        disabled
      ></b-form-input>
      <small class="text-danger" v-if="!bindingModel">{{
        bindingPlaceholder
      }}</small>
    </div>
    <div v-else>
      <vue-autosuggest
        v-model="bindingModel"
        :suggestions="[{ data: suggestions }]"
        :limit="limit"
        :should-render-suggestions="shouldRenderSuggestions"
        :get-suggestion-value="({ item }) => item[suggestionName]"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: bindingPlaceholder,
          title: bindingModelTitle,
          style: `text-align: ${textAlign}`,
        }"
        @input="onInputChange"
        @selected="onSelected"
      >
        <div slot-scope="{ suggestion }" class="d-flex">
          <slot name="custom" :suggestion="suggestion">
            {{ suggestion.item[suggestionName] }}
          </slot>
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback :state="state">
        {{ errorMessages }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      default: null,
      required: true,
    },
    suggestions: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    limit: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: String,
      default: 'Vui lòng không để trống',
    },
    suggestionName: {
      type: String,
      default: 'name',
    },
    textAlign: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      items: [],
      bindingPlaceholder: 'Nhập thông tin',
      bindingModelTitle: '',
    };
  },
  mounted() {
    this.bindingModelTitle = this.model;
  },
  created() {
    this.onInitial();
  },
  watch: {},
  computed: {
    bindingModel: {
      get: function () {
        return this.model;
      },
      set: function (newValue) {
        this.$emit('update:model', newValue);
        return;
      },
    },
  },
  methods: {
    onInitial() {
      if (this.placeholder) {
        this.bindingPlaceholder = `Nhập ${this.placeholder}`;
      }

      if (this.disabled && this.isEmpty(this.bindingModel)) {
        this.bindingPlaceholder = ` Chưa gán ${this.placeholder} `;
      }
    },
    onSelected(option) {
      this.bindingModelTitle = option ? option.item[this.suggestionName] : null;
      this.$emit('select', option);
    },
    onInputChange(text) {
      this.$emit('change', text);
    },
    isEmpty(value) {
      return !value || !value.trim().length;
    },
    shouldRenderSuggestions(size, loading) {
      return size >= 0 && !loading;
    },
  },
};
</script>

<style lang="scss" >
.auto-autosuggest-wrap-component {
  .autosuggest__results {
    position: absolute;
    width: 100%;
    max-height: 250px;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    z-index: 3;
    border-radius: 0 0 0.28rem 0.28rem;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
    font-weight: 400;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.35em + 1.1rem + 2px);
    line-height: 1.35;
    padding: 0.55rem 0.75rem;
    font-weight: 400;
    font-size: 0.925rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.28rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .autosuggest__input:focus {
    outline: none;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  // e3f2fd

  .autosuggest__results ul li {
    padding: 5px 8px 5px 8px;
    border-bottom: 0.1rem solid #e0e0e0;
  }
  .autosuggest__results .autosuggest__results-item:active {
    background-color: #e3f2fd;
  }
  .autosuggest__results
    .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #ddd;
  }
}
</style>
