<template>
  <b-modal
    :id="refModal"
    :ref="refModal"
    title="Thêm sản phẩm từ Excel"
    size="lg"
    hide-footer
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <span>
            <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
            đúng format, hoặc có thể tải ở
            <a :href="urlExcel">đây</a>
          </span>
        </div>
      </b-col>
    </b-row>
    <!-- Nhập file -->
    <b-row>
      <b-col cols='12'>
        <span>
          <b>Bước 2:</b> Nhập sản phẩm và tải lên
        </span>
        <UploadFileMulter
          v-on:send-file="getDataFromExcel"
          v-on:clear-file="clearFileExcel"
          :cmdUrl="'priority-cart/import-excel'"
        />
        <span v-if="fileName">Tên file: <b>{{ fileName }}</b>
        </span>
      </b-col>
    </b-row>

    <b-row class="step-3">
      <b-col>
        <span>
          <b>Bước 3:</b> Lưu
        </span>
      </b-col>
    </b-row>
    <b-container
      class="mt-4 pl-0 pr-0"
      v-show="importItems.length"
    >
      <h5>
        Danh sách sản phẩm từ file excel( Tổng:
        {{ importItems.length }} sản phẩm)
      </h5>
      <b-table
        responsive
        bordered
        hover
        :fields="fieldImport"
        :items="importItems"
        :current-page="currentPage"
        small
        class="product-table"
      >
        <template v-slot:cell(errors)="row">
          <div
            class="errors"
            v-if="row.item.errors.length"
          >
            <template v-for="(error, index) in row.item.errors">
              <p
                class="mb-0"
                :key="index"
              >{{ error}}</p>
            </template>
          </div>
        </template>
      </b-table>
    </b-container>
    <div class="footer">
      <b-button
        :disabled="!isValid"
        class="cta-btn"
        variant="primary"
        size="sm"
        @click="save"
      >Lưu</b-button>
      <b-button
        class="cta-btn ml-4"
        variant="secondary"
        size="sm"
        @click="$bvModal.hide(refModal)"
      >Hủy</b-button>
    </div>
  </b-modal>
</template>

<script>
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import { EventBus } from '@/core/services/event-bus';
import { BASE_URL } from '@/utils/constants';
import { convertPrice } from '@/utils/common';

export default {
  components: {
    UploadFileMulter,
  },
  data() {
    return {
      refModal: 'import-priority-cart',
      importItems: [],
      fieldImport: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
        },
        {
          key: 'imei',
          label: 'Mã IMEI',
          sortable: false,
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'errors',
          label: 'Lỗi',
          sortable: false,
        },
      ],
      fileName: '',
      urlExcel:
        BASE_URL + 'media/upload/priority-cart/Temp_Import_PriorityCart.xls',
      file: null,
      currentPage: 1,
      totalRow: 0,
      isValid: true,
    };
  },
  computed: {
    rowsTable() {
      return this.importItems.length;
    },
  },
  methods: {
    save() {
      EventBus.$emit('importPriorityCart', this.importItems);
      this.$bvModal.hide(this.refModal);
    },
    getDataFromExcel(data, fileName) {
      this.isValid = data.valid;
      this.fileName = fileName;
      this.importItems = data.rows.map((item) => {
        return {
          ...item,
        };
      });
    },
    clearFileExcel() {
      this.importItems = [];
      this.isValid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-btn {
  font-weight: 600;
  width: 70px;
}
.footer {
  margin-top: 20px;
}
.step-3 {
  margin-top: 20px;
}
.product-table {
  height: 400px;
}
</style>